import { gql } from "apollo-angular";

export const GET_DASHBOARD_PAGE = gql`
query getDashboardData{
  carouselCollection{
    items{
      carouselName
      heading
      desc
      cta
      asset {
        url
        title
      }
      link
      linkType
      target
      align
      backColor
      type
    }
  }
  marketingBannerCollection{
    items{
      title
      background
      asset {
        url
        title
      }
      target
      link
    }
  }
  howCanICollection(
    order:label_ASC
  ) {
    items {
      label
      linkType
      target
      link
    }
  }
}`;

export const GET_DASHBOARD_PAGE_PROMOTIONAL = gql`
query getDashboardData($promotionalContent: Boolean!){
  lcpCarouselCollection(where: { promotionalContent: $promotionalContent }){
    items{
      carouselName
      heading
      description
      cta
      asset {
        url
        title
      }
      link
      linkType
      target
      align
      backColor
      promotionalContent
    }
  }
  marketingBannerCollection{
    items{
      title
      background
      asset {
        url
        title
      }
      target
      link
    }
  }
  howCanICollection(
    order:label_ASC
  ) {
    items {
      label
      linkType
      target
      link
    }
  }
}`;

export interface CarouselItem {
  carouselName: string;
  heading: string;
  desc: string;
  cta: string;
  link: string;
  linkType: string;
  target: string;
  align: string;
  backColor: string;
  type: string;
  asset: Asset;
  promotionalContent: boolean; // Link for those who already own the product.. true or false
  description: string;
}

export interface Asset {
  url: string;
  title: string;
}

export interface MarketingBannerItem {
  title: string;
  background: string;
  target: string;
  link: string;
  asset: Asset;
}

export interface HowCanIItem {
  label: string;
  linkType: string;
  target: string;
  link: string;
}

export interface CarouselCollection {
  items: CarouselItem[];
}

export interface MarketingBannerCollection {
  items: MarketingBannerItem[];
}

export interface HowCanICollection {
  items: HowCanIItem[];
}

export interface DashboardContentfulPage {
  lcpCarouselCollection: CarouselCollection;
  marketingBannerCollection: MarketingBannerCollection;
  howCanICollection: HowCanICollection;
}