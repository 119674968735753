import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { FormValidationMessages } from '../../../interfaces/interfaces';
import { UsersService } from '../../../services/users.service';
import { ChangeLogComponent } from '../../modals/change-log/change-log.component';
import { TwoFactorMethod } from '../../../graphql/two-factor-method.queries.graphql';
import { UpdateUserData } from '../../../graphql/mutations/edit-user-data.query.graphql';
import { UsersComponent } from '../../users/users.component';
import { CreateChangeLog } from '../../../graphql/change-log.queties.graphql';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MessagingEmailService } from 'src/app/shared/services/email-service/messaging-email.service';
import { EmailInputData } from 'src/app/shared/graphql/messaging-email.mutation';
import { AzureService } from 'src/app/core/services/azure.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _formBuilder = inject(FormBuilder);
  private _portalDialogService = inject(UserPortalDialogService);
  public _dialog = inject(MatDialog);
  private _usersComponent = inject(UsersComponent);
  private _authService = inject(AuthenticationService);
  private _usersService = inject(UsersService);
  private _messagingEmailService = inject(MessagingEmailService);
  private _azureService = inject(AzureService);
  @Input() user!: UsersEdge;
  @Output() newEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() twoFactorIndicator: EventEmitter<string> = new EventEmitter<string>();

  userForm!: FormGroup;
  DEFAULT_PHONE_NUMBER = '000-000-0000';
  DEFAULT_NAME = '--';
  isSaving = this._usersService._isSaving;
  editUserInfo: boolean = false;
  twoFAmethodSubject = new BehaviorSubject<string>('');

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  formValidationMessages: FormValidationMessages = {
    confirmEmail: [
      { type: 'checkEmailIsMatched', message: 'Email confirmation does not match email address.' },
      { type: 'required', message: 'Email confirmation is required.' },
    ],
    phoneNumberFormat: [
      { type: 'required', message: 'Phone number is required.' },
      { type: 'minLength', message: 'Phone number must be at least 10 characters' },
    ]
  };

  ngOnInit(): void {
    this.UserFormInit();
    if (this.user.node.twoFactorEnabled) {
      this.validateTwoFAmethod();
    }
  }

  /**
   * Initializes the user form controls
   */
  UserFormInit(): void {
    this.userForm = this._formBuilder.group({
      userEmailAddress: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
      emailConfirmation: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
      firstName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(2)] }),
      lastName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(2)] }),
      phoneNumber: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(10)] }),
    }, {
      validators: this.validateFormFields('userEmailAddress', 'emailConfirmation'),
    });
  }

  /**
   * Enables the emailAddress input and buttons events.
   * @param user
   */
  enableUserForm(user: UsersEdge): void {
    this.editUserInfo = !this.editUserInfo;
    this.userForm.controls['userEmailAddress'].patchValue(user.node.email);
    this.userForm.patchValue(user.node);
    this.userForm.controls['emailConfirmation'].setValue(null);
  }

  /**
   * Compares the two email fields to evaluate their equality
   * if they're not, and ControlError is set to indicate that
   * @param emailCheck 
   * @param confirmEmailCheck 
   * @returns ValidationErrors
   */
  validateFormFields(emailCheck: string, confirmEmailCheck: string): ValidationErrors {
    return (_formGroup: FormGroup) => {
      const controlEmail = _formGroup.controls[emailCheck];
      const controlConfirmEmail = _formGroup.controls[confirmEmailCheck];

      if (controlEmail.value !== null) {
        if (controlConfirmEmail.errors && controlConfirmEmail.errors['required']) {
          this.user.node.email === controlEmail.value ? controlConfirmEmail.setErrors(null) : controlConfirmEmail.setErrors({ required: true });
        } else if ((this.user.node.email !== controlEmail.value) && (controlEmail.value !== controlConfirmEmail.value)) {
          controlConfirmEmail.setErrors({ checkEmailIsMatched: true })
        } else {
          controlConfirmEmail.setErrors(null)
        }
      }
    }
  }

  /**
   * Prevent the user from copy in clipboard events.
   * @param event
   */
  preventCopyingOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   * Prevent the user from paste in clipboard events.
   * @param event
   */
  preventPasteOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   * Evaluates the authentication method for the selected user
   *
   */
  validateTwoFAmethod(): void {
    this._usersService.getTwoFactorMethod(this.user.node.id)
      .then((result: TwoFactorMethod) => {
        if (result.data) {
          this.twoFAmethodSubject.next('Email OTP');
          this.twoFactorIndicator.emit('Email OTP enabled');
        } else {
          this.twoFAmethodSubject.next('Authenticator App');
          this.twoFactorIndicator.emit('Authenticator App enabled');
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }

  /**
   * Saves the new email value
   * @param user
   */
   /* istanbul ignore next */
  saveUserForm(user: UsersEdge): void {

    const userForm: UpdateUserData = {
      userName: user.node.userName,
      email: this.userForm.controls['userEmailAddress'].value,
      firstName: this.userForm.controls['firstName'].value ? this.userForm.controls['firstName'].value : this.DEFAULT_NAME,
      lastName: this.userForm.controls['lastName'].value ? this.userForm.controls['lastName'].value : this.DEFAULT_NAME,
      phoneNumber: this.userForm.controls['phoneNumber'].value ? this.userForm.controls['phoneNumber'].value.replace(/\D/g, '') : this.DEFAULT_PHONE_NUMBER
    }
  
    if(this.evaluateValidFormChanges(user, userForm)) {
      this._usersService.updateUserData(userForm)
      .then(async response => {
        this.editUserInfo = !this.editUserInfo;
        this._usersComponent.expandPanelAfterRequest = user.node.id;
        /*
        * Temporary check for development environment only.
        * This condition is in place until the email service is replicated in other environments.
        * Once the email service is available in staging/production, this check can be removed 
        * or adjusted to include those environments as well. /* 
        **/
        if (environment.env === 'dev' || environment.env === 'local') {
          await this.newSaveChangeLog(user);
        } else {
          await this.saveChangeLog(user);
        }
        this._portalDialogService.openUserPortalSnackBar(`User information updated`);
        this._usersComponent.getUsers();
      })
      .catch(error => {
        this.editUserInfo = !this.editUserInfo;
        this._portalDialogService.openUserPortalSnackBar(`Error updating user information, please try again`);
        console.error("Error: ", error);
      });
    }
  }

  /**
   * Evaluates if any inputs have a different value from the current user information
   * @param userInfo previous user information
   * @param form new value for the user information
   * @returns a boolean indicating if the form has any changes or not
   */
  evaluateValidFormChanges(userInfo: UsersEdge, form: UpdateUserData): boolean {
    const user = userInfo.node;
    if((user.email === form.email) && (user.firstName === form.firstName) && (user.lastName === form.lastName) && (user.phoneNumber === form.phoneNumber)){
      this._portalDialogService.openUserPortalSnackBar(`User information does not have changes`);
      return false;
    }
    return true; 
  }

  /**
  * Saves a new log when a user's email is changed
  */
  saveChangeLog(user: UsersEdge): void {
    const changeLog: CreateChangeLog = {
      fieldsChanged: this.validateUserDataChangesToLog(user),
      changedVia: 'Support Console',
      userId: user.node.id,
      changedById: this._authService.userId.value
    }
    this._usersService.saveChangeLog(changeLog);
  }

  /**
   * Saves a change log for a user profile update and sends a notification email.
   *
   * This method performs several operations sequentially to log user data changes, 
   * including fetching an email token, sending a notification email, and saving 
   * the change log. It ensures that if any of these operations fail, an error 
   * is thrown and a notification is displayed to the user.
   *
   * @param {UsersEdge} user - The user object containing the details of the user whose data has been updated.
   *
   * @throws {Error} Throws an error if any of the operations (email token fetch, email notification, or change log save) fail.
   *
   * @returns {Promise<void>} A promise that resolves when all operations have completed successfully. 
   */
  /* istanbul ignore next */
  async newSaveChangeLog(user: UsersEdge): Promise<void> {
    // Validate email credentials early
    const emailCredentials = this._azureService.emailCredentialsValue;
    if (!emailCredentials?.emailTemplate) {
      this._portalDialogService.openUserPortalSnackBar(`Profile user data was updated, but an error occurred while logging the change and sending
    the email. Please contact the development team to report this error.`);
      throw new Error("Email template not found. Cannot proceed with saveChangeLog operation.");
    }

    try {
      // Prepare data for operations
      const { fieldsChanged, buildMutationParams, emailChanged } = this.newValidateUserDataChangesToLog(user);

      const mutationInput: EmailInputData = {
        receiverEmail: emailChanged || user.node.email,
        username: user.node.userName,
        year: new Date().getFullYear().toString(),
        supportConsoleEmailId: emailCredentials.emailTemplate,
      };

      const changeLog: CreateChangeLog = {
        fieldsChanged,
        changedVia: 'Support Console',
        userId: user.node.id,
        changedById: this._authService.userId.value,
      };

      // Execute operations sequentially, stopping if any operation fails
      /*Step 1: Getting Email Access Token*/
      const emailTokenResult = await this._messagingEmailService.fetchEmailServiceToken(emailCredentials.clientEmailName, emailCredentials.clientEmailSecret);
      this.handleOperationResult(emailTokenResult, 'Email token fetch failed', 'Failed to get the access email token');
      /*Step 2: Adding the email access token into the graphql email request*/
      const emailResult = await this._messagingEmailService.sendNotificationEmail(mutationInput, buildMutationParams);
      this.handleOperationResult(emailResult, 'Email notification failed', 'Failed to send notification email');
      /*Step 3: Finally, logging update operation in the log table*/
      const logResult = await this._usersService.saveChangeLog(changeLog);
      this.handleOperationResult(logResult, 'Change log save failed', 'Failed to save change log');

    } catch (error) {
      console.error("Critical error in saveChangeLog:", error);
      throw error; // Re-throw to allow calling code to handle the error
    }
  }


  /**
   * Helper method to handle operation results
   * @param result PromiseSettledResult of the operation
   * @param errorName Name of the error for throw
   * @param errorMessage Message to log
   */
  /* istanbul ignore next */
  private handleOperationResult(
    result: any,
    errorName: string,
    errorMessage: string
  ): void {
    if (result.status === 'rejected') {
      console.error(`${errorMessage}:`, result.reason);
      throw new Error(`${errorName}: ${result.reason.message || result.reason}`);
    }
  }


  /**
   * Open the change log modal
   * @param firstName
   * @param lastName
   * @param email
   * @param loginHistory
   */
  openChangeLogDialog(
    id: string,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    userName: string,
  ): void {
    const _dialogRef = this._dialog.open(ChangeLogComponent, {
      width: '950px',
      data: {
        id,
        name: `${firstName ? firstName : ' - '} ${lastName ? lastName : ' - '}`,
        email,
        userName
      },
      disableClose: false
    });

    _dialogRef.afterClosed().subscribe((result) => {
      if (environment.env !== 'prod') {
        console.log(`_dialog result: ${result}`);
      }
    });
  }

  /**
   * Evaluates if the formFields' values changed to add them to "values changed" item.
   * @param user the object with the previous user data
   * @returns a text with the new user data
   */
   /* istanbul ignore next */  newValidateUserDataChangesToLog(user: UsersEdge): { fieldsChanged: string; buildMutationParams: string, emailChanged: string | null } {
    let fieldsChanged: string = '';
    let buildMutationParams: string = '';
    let emailChanged = null;

    if (user.node.email !== this.userForm.controls['userEmailAddress'].value) {
      fieldsChanged += `Email: '${this.userForm.controls['userEmailAddress'].value}'. `;
      buildMutationParams += ` { key: "Email", value: "Contact Email" }`;
      emailChanged = this.userForm.controls['userEmailAddress'].value;
    }
    if (user.node.firstName !== this.userForm.controls['firstName'].value) {
      fieldsChanged += `First name: '${this.userForm.controls['firstName'].value}'. `;
      buildMutationParams += `{ key: "FN", value: "First Name" }`
    }
    if (user.node.lastName !== this.userForm.controls['lastName'].value) {
      fieldsChanged += `Last name: '${this.userForm.controls['lastName'].value}'. `;
      buildMutationParams += `{ key: "LN", value: "Last Name" }`
    }
    if (user.node.phoneNumber !== this.userForm.controls['phoneNumber'].value) {
      fieldsChanged += `Phone number: '${this.userForm.controls['phoneNumber'].value}'.`;
      buildMutationParams += `{ key: "PN", value: "Phone Number" }`
    }

    return { fieldsChanged, buildMutationParams, emailChanged };
  }

  validateUserDataChangesToLog(user: UsersEdge): string {
    let fieldsChanged = '';

    if (user.node.email !== this.userForm.controls['userEmailAddress'].value) {
      fieldsChanged += `Email: '${this.userForm.controls['userEmailAddress'].value}'. `;
    }
    if (user.node.firstName !== this.userForm.controls['firstName'].value) {
      fieldsChanged += `First name: '${this.userForm.controls['firstName'].value}'. `;
    }
    if (user.node.lastName !== this.userForm.controls['lastName'].value) {
      fieldsChanged += `Last name: '${this.userForm.controls['lastName'].value}'. `;
    }
    if (user.node.phoneNumber !== this.userForm.controls['phoneNumber'].value) {
      fieldsChanged += `Phone number: '${this.userForm.controls['phoneNumber'].value}'.`;
    }
    return fieldsChanged;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}
