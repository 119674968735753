export const environment = {
  env: "dev",
  hideConsole: false,
  production: true,
  applications: {
    certified: {
      hostingUrl: "https://dev-certified.lcptracker.net/",
      login: "https://dev-certified-cdn.lcptracker.net"
    },
    lcptracker: {
      hostingUrl: "https://dev.lcptracker.net/",
      lcptrackerTenantGuid: "5fbdf4e1-b5f1-4bdf-8650-f6b3ba607710",
      lcptrackerClientId: "e558250f-71f5-41e7-a3a9-c6e3ce2aa0ac",
      login: "https://dev-cdn.lcptracker.net",
    },
    fortify: { 
      api: "https://devv2.fortify.lcptracker.net/api",
      username_old: 'https://dev.fortify.lcptracker.net/default/Account/Login?ReturnUrl=as%3D',
      v1: 'https://dev.fortify.lcptracker.net',
      v2: 'https://devv2.fortify.lcptracker.net',
    },
  },
  authentication: {
    issuer: "https://devv2.fortify.lcptracker.net",
    redirectUrl: `${window.location.origin}/login/success`,
    postLogoutRedirectUri: window.location.origin,
    clientId: "UserPortSpa",
    responseType: "code",
    scope: "openid profile offline_access email theidserveradminapi",
    silentRenewUrl: 'https://devv2.fortify.lcptracker.net/connect/token',
    renewTimeBeforeTokenExpiresValue:300
  },
  contentful: {
    contentType: {
      footer: "footer",
      howCanI: "howCanI",
      carousel: "carousel",
      marketingBanner: "marketingBanner",
      aboutUs: "aboutUs",
      infoCard: "infoCard",
    },
  },
  graphql: {
    rbs: "https://lcp-gateway-dev-appsvs.azurewebsites.net/graphql/",
    gateway: "https://lcp-gateway-dev-appsvs.azurewebsites.net/graphql/",
    geography: "https://lcp-geography-dev-appsvs.azurewebsites.net/graphql/",
    project: "https://lcp-project-dev-appsvs.azurewebsites.net/graphql/",
    wagedata: "https://lcp-wagedata-dev-appsvs.azurewebsites.net/graphql/",
    contentful: "https://graphql.contentful.com/content/v1/spaces/",
    email:'https://lcp-messagingsvs-dev-appsvs.azurewebsites.net/graphql/'
  },
  graphqlServerName: {
    gateway: "GatewayGraphQL",
    contentful: "ContentfulGraphQL",
    rbs: "RBSGraphQL",
    email: "EmailGraphQL",
  },
  onboardingClaims: {
    agreements: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Agreements",
    lastProfileChange: "https://devv2.fortify.lcptracker.net/identity/account/manage?culture=en",
    password: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/ChangePassword",
    twoFactorAuthentication: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/TwoFactorAuthentication",
    phoneNumber:'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/PhoneNumber',
    email:'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Email',
    providers:'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/ExternalLogins',
    sessions:'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Sessions'
  },
  waldoApi: "https://lcp-whereis-all-appsvs.azurewebsites.net",
  LcpTrackerApi: 'https://dev.lcptracker.net/api/user',
  CertifiedApi: 'https://dev-certified.lcptracker.net/api/user',
  waldoEnv: 'd',
  azureServices: "https://lcp-fortify-legacydatabridge-dev-appsvs.azurewebsites.net/Keys/GetKey?keyName=",
  userPortalHost: 'https://dev.userportal.lcptracker.net',
  lcptrackerPlusPlans: {
    monthly: 'https://csotest.chargifypay.com/subscribe/3bdzp574tb2j/lcpt-plus',
    annual: ''
  },
};
