import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { AUTHORIZATION_CONTROLS_MENU_OPTIONS, AUTHORIZATION_CONTROLS_PASSWORD_CHILD, AUTHORIZATION_CONTROLS_SSO_CHILD, CANARY_OPTION, COMMON_MENU_ITEMS, DELETE_USERS_OPTION, FEATURE_PANEL_OPTION, LCP_PLUS, MENU, SUPPORT_CONSOLE_MENU_OPTION, USER_CREATOR_OPTION } from '../menu';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthCanaryService } from 'src/app/shared/services/auth-canary/auth-canary.service';
import { CsoPaymentService } from 'src/app/features/payment-cso/services/cso-payment.service';

@Injectable({
  providedIn: 'root'
})
export class MenuOptionsService {

  private readonly authenticationService = inject(AuthenticationService);
  private readonly authenticationCanaryService = inject(AuthCanaryService);
  private readonly csoPaymentService = inject(CsoPaymentService);


  constructor() { }

  async getMenuOptions(): Promise<MENU[]> {
    return new Promise<MENU[]>(async (resolve) => {
      const currentUserRolesFromFortify = this.authenticationService.fortifyRoles.value;
      const currentUserRolesFromRBS = this.authenticationService.rbsRoles.value;

      let menuOptions: MENU[] = [...COMMON_MENU_ITEMS];

      // Common Requirements
      const isUserIpInTheWhitelist = await firstValueFrom(this.authenticationService.isUserIpInTheWhitelist());
      const staffEmails = ['@cikume.com', '@lcptracker.com']
      const isStaffUsername = staffEmails.some(staffEmail => this.authenticationService.userName.value.includes(staffEmail))
      const isSupportStaffRole = currentUserRolesFromRBS.includes('SupportStaff');
      const isSupportStaffManagerRole = currentUserRolesFromRBS.includes('SupportStaffManager');
      const isFeatureManagerRole = currentUserRolesFromRBS.includes('FeatureMgmt');

      //LCP+
      const isCsoEnabled = this.csoPaymentService.csoStatusEnabled();
      const isCOntractor = this.csoPaymentService.isContractor();
      const hasLicense = this.csoPaymentService.hasValidLicense();
      if (isCsoEnabled && isCOntractor && !hasLicense) {
        menuOptions.push(LCP_PLUS);
      }

      // Support Console
      if ((isSupportStaffManagerRole || isSupportStaffRole) && isStaffUsername && isUserIpInTheWhitelist) {
          menuOptions.push(SUPPORT_CONSOLE_MENU_OPTION);
      }

      // User Creator
      const isUserCreatorRole = currentUserRolesFromFortify.includes('CreateNewUserInUserPortal');
      /* istanbul ignore if */
      if ((isUserCreatorRole || isSupportStaffManagerRole) && isUserIpInTheWhitelist) {
        menuOptions.push(USER_CREATOR_OPTION);
      }

      // Authentication Controls
      const isItSecurityTesterRole = currentUserRolesFromRBS.includes('IT Security Tester');
      const isItSecurityManagerRole = currentUserRolesFromRBS.includes('IT Security Manager');


      const authorizationNode = { ...AUTHORIZATION_CONTROLS_MENU_OPTIONS };
      let hasChild: boolean = false;

      // Password Management
      if (isUserCreatorRole) {
        hasChild = true;
        authorizationNode.node?.children.push(AUTHORIZATION_CONTROLS_PASSWORD_CHILD);
      }

      // SSO Management
      if (isItSecurityTesterRole || isItSecurityManagerRole) {
        hasChild = true;
        authorizationNode.node?.children.push(AUTHORIZATION_CONTROLS_SSO_CHILD);
      }

      // Creare PARENT authorization node with children (If there's aplicable)
      if (hasChild) {
        menuOptions.push(authorizationNode);
      }

      // Delete Users
      const removeUsers = currentUserRolesFromFortify.includes('RemoveUsers');
      if (removeUsers) {
        menuOptions.push(DELETE_USERS_OPTION);
      }

      /*
      ################################################################
                               Canary Tabs
      ################################################################
      */
      /* istanbul ignore if */
      if (environment.env !== 'prod') {
        const userPortalId = await this.authenticationCanaryService.getUserPortalServiceId();
        const featureKey = 'Experimental-TestModule';

        const canaryUser = this.authenticationCanaryService.getCanaryUserValue;
        const canaryOrg = this.authenticationCanaryService.getCanaryOrgValue;

        /// FEATURE MODULE
        const claimResponse = await this.authenticationCanaryService.getClaimsForCanary('network-only', featureKey, userPortalId.id);
        if (claimResponse) {
          const isFeatureEnabled = this.authenticationCanaryService.isFeatureEnabled(claimResponse);

          console.log('TestModule: ', isFeatureEnabled);
          console.log('Canary User: ', canaryUser);
          console.log('Canary Org: ', canaryOrg);

          if (isFeatureEnabled && (canaryUser || canaryOrg)) {
            menuOptions.push(CANARY_OPTION);
          }
        }
      }
      /*
      ################################################################
                               End Canary Tabs
      ################################################################
      */

      /* istanbul ignore if */
      if (isFeatureManagerRole && isUserIpInTheWhitelist && isStaffUsername) {
        menuOptions.push(FEATURE_PANEL_OPTION);
      }


      if (environment.env !== 'prod') {
        console.log('WHITELIST: ', isUserIpInTheWhitelist);
        console.log('CREATE USER PORTAL USERS ', isUserCreatorRole);
        console.log('VALID DOMAIN: ', isStaffUsername);
        console.log('IT SECURITY MANAGER: ', isItSecurityManagerRole);
        console.log('IT SECURITY TESTER: ', isItSecurityTesterRole);
        console.log('SUPPORT STAFF MANAGER: ', isSupportStaffManagerRole);
        console.log('FEATURE MANAGER: ', isFeatureManagerRole);
      }

      resolve(menuOptions);
    });
  }


}