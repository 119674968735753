import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { GetUserDataByIDService } from 'src/app/shared/layouts/portal/services/get-user-data-by-id.service';
import { cleanSessionStorage, retrieveDecryptedFromSessionStorage } from 'src/app/shared/helpers';
import { CsoPaymentService } from '../../payment-cso/services/cso-payment.service';

/**
 * Resolver function to manage the initialization of the LCP+ workflow.
 *
 * This resolver checks the CSO status, verifies if the user belongs to at least one
 * 'CONTRACTOR' organization, and determines if the organization has valid licenses.
 * The results are stored in signals within the `CsoPaymentService`.
 *
 * @returns {Promise<boolean>} Returns `true` if the workflow is valid, otherwise `false`.
 */
export const checkLcpPlusWorkflowResolver: ResolveFn<boolean> = async () => {
  const csoPaymentService = inject(CsoPaymentService);
 // const router = inject(Router);
  const getUserDataByIDService = inject(GetUserDataByIDService);

  try {
    const lcpChecked = retrieveDecryptedFromSessionStorage('LCPlusChecked');

    if(lcpChecked){
      console.warn('LcpChecked true');
      cleanSessionStorage(['LCPlusChecked'])
      return true;
    }
    /**
     * Fetch the CSO status from the payment service.
     * If the status is not "enabled", the user is redirected to the dashboard.
     */
    const csoStatus = await csoPaymentService.getCSOStatus('cache-first');

    if (csoStatus.toLocaleLowerCase() !== 'enabled') {
      console.warn('CSO IS DISABLED');
      csoPaymentService.csoStatusEnabled.set(false);
      //router.navigateByUrl('/dashboard', { replaceUrl: true });
      return false;
    }

    console.warn('CSO IS ENABLED');
    csoPaymentService.csoStatusEnabled.set(true);

    /**
     * Retrieve the current organizations associated with the user.
     * Only organizations of type 'CONTRACTOR' are relevant for this workflow.
     */
    const currentOrgs = await getUserDataByIDService.getContractorsValue;
    let contractorOrgs: any[] = [];

    if (!Array.isArray(currentOrgs)) {
      if (currentOrgs?.org.orgType === 'CONTRACTOR') {
        contractorOrgs = [currentOrgs];
      }
    } else {
      contractorOrgs = currentOrgs.filter((org) => org.org.orgType === 'CONTRACTOR');
    }

    /**
     * If no 'CONTRACTOR' organizations are found, update the signal and return `false`.
     */
    if (!contractorOrgs.length) {
      console.warn('IT IS NOT A CONTRACTOR');
      csoPaymentService.isContractor.set(false);
      return false;
    }

    /**
     * Extract the organization IDs for valid contractor organizations.
     */
    console.warn('IT IS A CONTRACTOR');
    csoPaymentService.isContractor.set(true);
    const contractorOrgIds = contractorOrgs.map((org) => org.org.id);

    /**
     * Check if the contractor organizations have valid licenses.
     * Update the signal based on the presence of valid licenses.
     */
    const licensesResponse = await csoPaymentService.getTypeOfLicensesByOrgIds(contractorOrgIds);
    const hasLicense = !!licensesResponse && licensesResponse.length > 0;

    csoPaymentService.hasValidLicense.set(hasLicense);

    console.warn('HAS LICENSE?', hasLicense);

    // Return true if licenses are valid, otherwise false.
    return hasLicense;
  } catch (error) {
    /**
     * Handle errors during the workflow initialization process.
     * Update the signals to reflect an invalid state and log the error.
     */
    console.error('An error occurred during the CSO workflow initialization:', error);
    csoPaymentService.csoStatusEnabled.set(false);
    csoPaymentService.hasValidLicense.set(false);
    return false;
  }
};